import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/components/Header.tsx");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/@fortawesome/fontawesome-svg-core/styles.css");
;
import(/* webpackMode: "eager" */ "/vercel/path0/app/global.css");
;
import(/* webpackMode: "eager", webpackExports: ["SpeedInsights"] */ "/vercel/path0/node_modules/@vercel/speed-insights/dist/next/index.mjs");
;
import(/* webpackMode: "eager", webpackExports: ["__esModule","default"] */ "/vercel/path0/node_modules/next/dist/client/app-dir/link.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/font/google/target.css?{\"path\":\"app/layout.tsx\",\"import\":\"Space_Grotesk\",\"arguments\":[{\"subsets\":[\"latin\"],\"display\":\"swap\",\"variable\":\"--font-space-grotesk\"}],\"variableName\":\"space_grotesk\"}");
;
import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/pliny/chunk-MBYDL2NI.js");
;
import(/* webpackMode: "eager", webpackExports: ["AlgoliaSearchProvider"] */ "/vercel/path0/node_modules/pliny/chunk-O6XQYU4T.js");
;
import(/* webpackMode: "eager", webpackExports: ["KBarSearchProvider"] */ "/vercel/path0/node_modules/pliny/chunk-XWEUNOHX.js");
